export default {
  lightGrey: "grey",
  colorGreyDark: "#333",
  colorGreyLight: "#8e9297",
  colorBrand: "#EA5252",
  fontBody: "neue-haas-unica, sans-serif",
  fontFancy: "p22-mackinac-pro,serif",
  maxWidth: "960px",
  breakpointMedium: "1100px",
  breakpointMediumMin: "1101px", // just means we don't need to calcs to use the breakpoint in min-width queries
  breakpointSmall: "800px",
  breakpointSmallMin: "801px",
  padding: "40px",
}
